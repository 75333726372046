import * as React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"

import Contact from "../components/contact"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contato" />
      <Contact />
    </Layout>
  )
}

export default ContactPage
